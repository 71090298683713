<template>
  <v-menu close-on-click close-on-content offset-y @input="onMenuChange">
    <template v-slot:activator="{ on }">
      <v-btn icon class="mx-2" small v-on="on">
        <v-badge color="green" :content="counter" overlap>
          <v-icon small>mdi-bell-ring</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="!notifications.length">
        <v-list-item-subtitle>
          Non ci sono notifiche da visualizzare
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item v-for="item in notifications" :key="item.ts">
        <v-list-item-icon>
          <h3 color="indigo">{{ item.value }}</h3>
        </v-list-item-icon>

        <!--        <v-list-item-content>-->
        <!--          <v-list-item-title>{{ item.gate_name }}</v-list-item-title>-->
        <!--          <v-list-item-subtitle>{{ item.location_name }}</v-list-item-subtitle>-->
        <!--        </v-list-item-content>-->
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import {
  AttachNotificationHandler,
  RegisterNotifications,
} from '@services/firebase.js'

export default {
  name: 'UserNotifications',
  computed: {
    ...authComputed,
    counter() {
      return '' + this.notifications.filter((n) => !n.read).length
    },
  },
  data: () => ({
    notifications: [],
  }),

  mounted() {
    this.onMounted()
  },
  methods: {
    onMenuChange(val) {
      if (val) return

      this.notifications
        .filter((n) => !n.read)
        .forEach((n) => {
          n.read = true
        })
    },
    async onMounted() {
      try {
        await RegisterNotifications()
        AttachNotificationHandler(this.onNotification)
      } catch (err) {
        await this.$dialog.notify.warning(
          'Attenzione, le notifiche push sono state disabilitare. Prego modificare le impostazioni del proprio browser.'
        )
      }
    },
    onNotification({ data }) {
      console.info('onNotification', data)
      this.notifications.push({
        ...data,
        ts: Date.now(),
        read: false,
      })

      this.$dialog.notify.info("E' stata ricevuta una richiesta di supporto.", {
        position: 'bottom-right',
        timeout: 5000,
        // actions: [
        //   {
        //     text: 'Vai',
        //     color: 'white',
        //     outlined: true,
        //     key: 'goTo',
        //   },
        // ],
      })
      // .then((res) => console.info('res:', res))
    },
    onBackgroundMessage() {},
  },
}
</script>
