<template>
  <v-app-bar
    app
    flat
    :class="dark_mode ? 'shadow-dark' : 'shadow'"
    hide-on-scroll
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="toggleDrawer"
    />

    <AppBarLogo v-else class="ml-6 mr-6" />

    <v-toolbar-title>
      <AppBarTitle />
    </v-toolbar-title>
    <v-spacer />

    <template v-if="currentUser && !isMagicLink">
      <UserToolbarInfo class="mr-4" />
      <UserTodayTicket
        v-if="
          canUser('tickets', 'update') && canDisplayNotificationsFor('tickets')
        "
      />
      <UserNotifications v-if="canDisplayNotifications" />

      <v-menu close-on-click close-on-content offset-y nudge-width="160">
        <template v-slot:activator="{ on }">
          <v-btn icon class="mr-md-2" v-on="on">
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="$router.push({ name: 'profile' })"
            >
              {{ currentUser.user.first_name }} {{ currentUser.user.last_name }}
              <br />
              <small class="text--secondary">
                {{ currentUser.user.username }}
              </small>
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item to="/manage/logout">
            <v-list-item-title>
              <v-icon left size="medium">mdi-logout-variant</v-icon> Esci
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'
import UserNotifications from '@components/common/UserNotifications.vue'
import UserToolbarInfo from '@components/common/UserToolbarInfo.vue'
import AppBarTitle from '@components/structure/app-bar-title.vue'
import UserTodayTicket from '@components/common/UserTodayTicket.vue'
import AppBarLogo from '@components/structure/app-bar-logo.vue'
import utils from '@mixins/utils.js'

export default {
  name: 'AppBar',
  components: {
    AppBarLogo,
    AppBarTitle,
    UserToolbarInfo,
    UserNotifications,
    UserTodayTicket,
  },
  mixins: [utils],
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...authComputed,
    ...mapState('ui', ['dark_mode']),
    canDisplayNotifications() {
      return this.canUser('notifications', 'read')
    },
  },
  methods: {
    ...mapMutations('ui', {
      toggleDrawer: 'TOGGLE_DRAWER',
    }),
    canDisplayNotificationsFor(section) {
      return (
        this.canDisplayNotifications && this.canUser(section, 'notifications')
      )
    },
  },
}
</script>

<style scoped>
.shadow {
  background-color: #ffffff !important;
  box-shadow: 0 2px 30px -1px rgba(85, 85, 85, 0.08),
    0 4px 20px 0 rgba(85, 85, 85, 0.06), 0 1px 20px 0 rgba(85, 85, 85, 0.03) !important;
}

.shadow-dark {
  box-shadow: 0 2px 30px -1px rgba(0, 0, 0, 0.08),
    0 4px 20px 0 rgba(0, 0, 0, 0.06), 0 1px 20px 0 rgba(0, 0, 0, 0.03) !important;
}

.appbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.appbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
</style>
