<template>
  <div>
    <span class="text-uppercase font-weight-light">
      {{ webenv }}
    </span>
    <span class="text-uppercase font-weight-bold">
      {{ currentCustomerName | placeholder('Dashboard') }}
    </span>
  </div>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import utils from '@mixins/utils.js'
export default {
  name: 'AppBarTitle',
  mixins: [utils],
  computed: {
    ...authComputed,
  },
}
</script>
