<template>
  <v-menu close-on-click close-on-content offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon class="mx-2" small v-on="on">
        <v-badge color="orange" :content="counter" overlap>
          <v-icon small>mdi-text-box-check</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="!pending.length">
        <v-list-item-subtitle>
          Non ci sono ticket con appuntamento in data odierna
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item
        v-for="item in pending"
        :key="item.ts"
        :to="{ name: 'tickets_detail', params: { id: item.id } }"
      >
        <v-list-item-icon>
          <h5 color="indigo">{{
            item.date_appointment | momentOr('hh:mm')
          }}</h5>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.customer ? item.customer.common_name : '-' }}
          </v-list-item-title>
          <v-list-item-content>
            <small>{{ item.external_id }}</small>
          </v-list-item-content>
          <v-list-item-subtitle>
            <v-chip label small>{{ item.status }}</v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserTodayTicket',
  computed: {
    ...authComputed,
    ...mapState('tickets', {
      pending: 'todayPending',
    }),
    counter() {
      return `${this.pending.length}`
    },
  },
  mounted() {
    this.getPendingTickets()
  },
  methods: {
    ...mapActions('tickets', ['getPendingTickets']),
  },
}
</script>
